/* ****************************************************************************
 * ADMIN-LTE OVERRIDE
 */
/* ****************************************************************************
 * ADAMA-WEB OVERRIDE
 */
/* ****************************************************************************
 * APPLICATION
 */
.crud-search-box input + span .btn {
  transition: border-color 0.15s ease-in-out 0s;
}
.crud-search-box input:focus + span .btn {
  border-bottom: 1px solid #3c8dbc;
  border-right: 1px solid #3c8dbc;
  border-top: 1px solid #3c8dbc;
}
@media (max-width: 767px) {
  .crud-content-header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .crud-content-header form {
    margin-right: 0;
  }
  .crud-content-header crud-search-field {
    order: 1;
    width: 100%;
  }
  .crud-content-header btn-create {
    margin-bottom: 10px;
  }
  .crud-content-header h1 {
    order: -1;
    flex: 1;
    margin-bottom: 10px;
  }
}
btn-create + crud-search-field form {
  margin-right: 10px;
}
.crud-transcluded-actions ul {
  padding: 0;
}
.crud-transcluded-actions li {
  display: block;
}
.crud-transcluded-actions a,
.crud-transcluded-actions button {
  background: transparent;
  clear: both;
  color: #777777;
  display: block;
  font-weight: 400;
  line-height: 1.42857143;
  padding: 3px 20px;
  white-space: nowrap;
}
.crud-transcluded-actions a:hover,
.crud-transcluded-actions button:hover {
  background-color: #e1e3e9;
  color: #333333;
}
.crud-transcluded-actions button {
  border: 0;
  display: block;
  text-align: left;
  width: 100%;
}
.crud-transcluded-actions a > .glyphicon,
.crud-transcluded-actions a > .fa,
.crud-transcluded-actions a > .ion,
.crud-transcluded-actions button > .glyphicon,
.crud-transcluded-actions button > .fa,
.crud-transcluded-actions button > .ion {
  margin-right: 10px;
}
.crud-image {
  max-height: 10vh;
  max-width: 100%;
}
table .crud-image {
  max-height: 10vh;
}
view-attribute > .row > div:first-child {
  font-weight: bold;
}
input.ng-invalid,
select.ng-invalid,
textarea.ng-invalid {
  border-bottom: 1px solid #d9534f;
}
.famfamfam-flags {
  display: inline-block;
}
.file-upload--zone {
  background: rgba(120, 120, 120, 0.5);
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}
.file-upload--zone input {
  display: none;
}
.file-upload--zone .only-dragover {
  display: none;
}
.file-upload--zone.dragover {
  background: rgba(0, 120, 0, 0.5);
}
.file-upload--zone.dragover span {
  display: none;
}
.file-upload--zone.dragover .only-dragover {
  display: block;
}
.file-upload--dropzone {
  background: rgba(255, 120, 0, 0.5);
}
binary-file-definition {
  display: block;
}
binary-file-definition .file-upload--label {
  font-weight: bold;
}
binary-file-definition .file-upload--zone {
  margin-top: 0;
}
binary-file-definition .file-upload--zone img {
  max-height: 100%;
}
binary-file-definition .file-upload--zone span {
  flex: 1;
}
#loading-bar .bar,
#loading-bar .peg {
  height: 5px;
}
#loading-bar .bar {
  background: #222d32;
}
.modal ~ #loading-bar .bar {
  background: #ecf0f5;
}
[ng-message] {
  background: #fcf8e3;
  margin-bottom: 5px;
  padding: 5px 10px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
  height: auto;
}
.user-menu > a i {
  font-size: 1.5em;
  vertical-align: middle;
}
.user-menu .user-header i {
  color: white;
  font-size: 5em;
}
.angular-table-pager {
  margin: 20px 0;
}
.angular-table-pager .pagination {
  margin: 0;
}
.angular-table-pager .pagination a {
  cursor: pointer;
  height: 2.5em;
}
.angular-table-pager > div + div {
  text-align: center;
}
.angular-table-pager > div + div + div {
  text-align: right;
}
@media (max-width: 767px) {
  .angular-table-pager > div {
    display: inline;
  }
  .angular-table-pager ul {
    vertical-align: middle;
  }
}
.table-responsive {
  display: flex;
  flex-direction: column;
  padding-bottom: 5em;
}
.table-responsive table {
  order: 1;
  margin-top: 10px;
}
.premaking {
  background-color: #dd4b39;
}
.making {
  background-color: #f39c12;
}
.postmaking {
  background-color: #0073b7;
}
.shipping {
  background-color: #00a65a;
}
.watchlist {
  background-color: #605ca8;
}
.billing {
  background-color: #0073b7;
}
[class^='icon-'] {
  font-size: 150%;
}
.product-status-START,
.product-status-PRINT,
.product-status-FABRIC_CUT,
.product-status-FABRIC_CUT_WAIT,
.product-status-FABRIC_WAITING,
.product-status-FABRIC_NO_MORE {
  background: #dd4b39 !important;
  color: white !important;
  text-align: center;
}
.product-status-OFFICE_OUT,
.product-status-WORKSHOP_IN,
.product-status-PATTERN_CUT,
.product-status-PATTERN_MODIFOK,
.product-status-PATTERN_OK,
.product-status-PATTERN_PROB,
.product-status-WORKSHOP_OUT,
.product-status-INITIALS {
  background: #f39c12 !important;
  color: white !important;
  text-align: center;
}
.product-status-QUALITY_OK,
.product-status-TAGGED,
.product-status-PACKAGED {
  background: #0073b7 !important;
  color: white !important;
  text-align: center;
}
.product-status-READY_FOR_SHIPPING,
.product-status-SHIPPED {
  background: #00a65a !important;
  color: white !important;
  text-align: center;
}
.edit-readonly {
  margin-bottom: 10px;
}
.product-type-chooser {
  margin: 20px 0;
}
btn-create + div {
  margin-right: 10px;
}
.qrcode {
  margin-bottom: 10px;
  text-align: center;
}
.nav-tabs > li > product-shirt-sheet-print > a {
  color: #404040;
  display: block;
  padding: 10px 15px;
  cursor: pointer;
}
.inline-edit {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 5px;
  width: 100%;
  min-height: 25px;
}
